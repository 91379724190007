<template>
  <v-dialog
    v-model="dialogLeads"
    @click:outside="$emit('update:dialogLeads', false)"
    @keydown.esc="$emit('update:dialogLeads', false)"
    max-width="1000px"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title class="pa-4 primary white--text">
        <v-icon left dark>mdi-information</v-icon> Informações

        <v-spacer></v-spacer>

        <v-sheet
          v-if="usuario.maestria && showMaestria && $vuetify.breakpoint.smAndUp"
          color="white"
          rounded
          class="text-body-2 px-2 py-1 mr-4"
        >
          <v-icon small>mdi-school-outline</v-icon>
          Maestria
        </v-sheet>

        <v-sheet
          v-if="usuario.user_agent && $vuetify.breakpoint.smAndUp"
          color="white"
          rounded
          class="text-body-2 px-2 py-1 mr-4"
        >
          <v-icon small>mdi-cellphone</v-icon>
          {{ usuario.user_agent | excerpt(15) }}
        </v-sheet>

        <v-sheet
          v-if="usuario.renda_anual && $vuetify.breakpoint.smAndUp"
          color="white"
          rounded
          class="text-body-2 px-2 py-1"
        >
          <v-icon small>mdi-currency-usd</v-icon>
          {{ respostaFaturamento }}
        </v-sheet>

        <v-icon
          v-if="usuario.perfil"
          class="ml-3"
          :color="usuario.perfil === 'E' ? 'xbColor' : 'timer'"
          >mdi-heart</v-icon
        >
        <v-icon
          v-if="usuario.perfil"
          class="ml-3"
          :color="usuario.perfil === 'T' ? 'xbColor' : 'timer'"
          >mdi-brain</v-icon
        >
        <v-icon
          v-if="usuario.perfil"
          class="ml-3"
          :color="usuario.perfil === 'A' ? 'xbColor' : 'timer'"
          >mdi-run</v-icon
        >

        <v-icon
          class="ml-6"
          @click="$emit('update:dialogLeads', false)"
          color="white"
        >
          mdi-close
        </v-icon>
      </v-card-title>

      <div class="d-flex">
        <v-tabs v-model="tab" :centered="$vuetify.breakpoint.smAndDown">
          <v-tab>Dados Gerais</v-tab>
          <v-tab>Anotações</v-tab>
        </v-tabs>
        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-sheet
            v-if="usuario.vendedor"
            color="white"
            rounded
            class="d-flex align-center justify-end text-body-2 px-2 py-1 mr-4"
            min-width="300px"
          >
            <v-icon left>mdi-account-tie</v-icon>
            Vendedor: {{ usuario.vendedor.nome }}
          </v-sheet>
          <DialogAddVendedor
            v-else
            :user_id="usuario.id"
            @add-vendedor="addVendedor"
            @reload-leads="$emit('reload-data')"
          />
        </template>
      </div>

      <v-divider></v-divider>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item class="px-4 py-7">
            <v-row>
              <v-col
                cols="12"
                md="auto"
                :class="{
                  'd-flex justify-center': $vuetify.breakpoint.smAndDown,
                }"
              >
                <v-avatar width="170px" height="170px">
                  <v-img
                    v-if="usuario.avatar"
                    cover
                    :src="usuario.avatar"
                  ></v-img>
                  <v-img
                    v-else
                    cover
                    :src="require('@/assets/avatar_placeholder.png')"
                  ></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="">
                <v-row>
                  <!-- Nome -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="usuario.nome"
                      label="Nome"
                      readonly
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Email -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="usuario.email"
                      label="E-mail"
                      readonly
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Instagram -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="usuario.instagram"
                      label="Instagram"
                      readonly
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Celular -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="usuario.celular"
                      label="Celular"
                      readonly
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Motivo Participação -->
                  <v-col cols="12" md="12">
                    <v-textarea
                      v-model="usuario.motivo_participacao"
                      label="Por que resolveu participar do X-Bussines"
                      readonly
                      dense
                      rows="2"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Empresa Dificuldade -->
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="usuario.empresa_dificuldade"
                  label="Qual a maior dificuldade que tem para fazer o Negócio crescer ou atingir as metas?"
                  readonly
                  dense
                  rows="2"
                  auto-grow
                ></v-textarea>
              </v-col>

              <!-- Desejo Empresa -->
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="usuario.desejo_empresa"
                  label="Qual o maior desejo quanto ao Negócio? Para onde quer levar ele?"
                  readonly
                  dense
                  rows="2"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <AnotacoesLeeds :user_id="usuario.id" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogLead",

  components: {
    AnotacoesLeeds: () => import("./anotacoes/AnotacoesLeeds.vue"),
    DialogAddVendedor: () => import("./DialogAddVendedor.vue"),
  },

  props: {
    dialogLeads: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      require: true,
    },
    showMaestria: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: true,
      tab: 0,
      anotacoes: [],
      usuario: {},
    };
  },

  computed: {
    respostaFaturamento() {
      let result = "";
      switch (this.usuario.renda_anual) {
        case "1":
          result = "Menos de R$ 100 Mil";
          break;
        case "2":
          result = "De R$ 100 mIl a R$ 250 Mil";
          break;
        case "3":
          result = "De R$ 250 mIl a R$ 500 Mil";
          break;
        case "4":
          result = "De R$ 500 mil a R$ 1 Milhão";
          break;
        case "5":
          result = "De R$ 1 Milhão a R$ 5 Milhões";
          break;
        case "6":
          result = "Maior que R$ 5 Milhões";
          break;
      }

      return result;
    },
  },

  methods: {
    addVendedor(vendedor) {
      this.usuario.vendedor = vendedor;
    },
  },

  async mounted() {
    this.loading = true;
    this.usuario = this.item;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
